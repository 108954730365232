import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatDuration',
    standalone: true
})
export class FormatDurationPipe implements PipeTransform {
    private ONE_MINUTE = 60;
    private ONE_HOUR = 3600;

    private formatters = {
        ms: (duration): string => {
            return `${duration.minutes ? duration.minutes + 'm' : ''} ${
                duration.seconds ? duration.seconds + 's' : ''
            }`;
        },
        h: (duration): string => {
            return `${duration.hours ? duration.hours + 'h' : ''}`;
        },
        hm: (duration): string => {
            return `${duration.hours ? duration.hours + 'h' : ''} ${
                duration.minutes ? duration.minutes + 'm' : ''
            }`;
        },
        hms: (duration): string => {
            let result = '';
            if (duration.value < this.ONE_MINUTE) {
                result = `0:${duration.seconds}`;
            } else if (duration.value >= this.ONE_MINUTE && duration.value < this.ONE_HOUR) {
                result = `${duration.minutes}:${duration.seconds}`;
            } else {
                let minutesFormatted = this.formatNumber(duration.minutes);
                result = `${duration.hours}:${minutesFormatted}:${duration.seconds}`;
            }
            return result;
        }
    };

    private formatNumber(num: number): string {
        return num >= 10 ? num.toString() : '0' + num;
    }

    public getDuration(value: number) {
        return {
            value: value,
            hours: Math.floor(value / this.ONE_HOUR),
            minutes: Math.floor((value % this.ONE_HOUR) / this.ONE_MINUTE),
            seconds: this.formatNumber(Math.floor((value % this.ONE_HOUR) % this.ONE_MINUTE))
        };
    }

    transform(value: number, format: 'h' | 'hm' | 'ms' | 'hs' | 'hms' = 'hms') {
        if (value <= 0 || !value) {
            return value;
        }

        const duration = this.getDuration(value);

        return (
            (this.formatters[format] && this.formatters[format](duration)) ||
            this.formatters.hms(duration)
        );
    }
}

